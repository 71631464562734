import React, { useEffect, useState } from "react";

import { useAuth } from "../contexts/Auth";
import { useToast } from "../contexts/ToastContext";
import API from "../api/api";
import Loader from "../components/Loader";

const Settings: React.FC = () => {
  const auth = useAuth();
  const accountId = auth?.currentUser?.selectedAccount?.accountId;
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();
  const [externalWallets, setExternalWallets] = useState([]);
  const [bankAccounts, setBankAccounts] = useState<
    Array<{
      id: string;
      routingNumber: string;
      bankName: string;
      type: string;
      last4: string;
      description: string;
      preferredPayment: boolean;
      hasOffRampAccess: boolean;
      hasOnRampAccess: boolean;
    }>
  >([]);
  const [company, setCompany] = useState<null | {
    name: string;
    address: string;
    id: string;
    offRampWithdrawalFrequency: string;
    onRampWithdrawalFrequency: string;
    paymentId: string;
    preferredOnrampAssetId: string;
    preferredOnrampWalletId: string;
    hasOffRampAccess: boolean;
    hasOnRampAccess: boolean;
  }>(null);
  const [onRampOptions, setOnRampOptions] = useState<any[]>([]);
  const [, setShowDisclaimer] = useState<any>(null);

  const updateAccount = async (prop: string, value: string) => {
    setLoading(true);
    await API.patch(`business/${accountId}`, {
      [prop]: value,
    });
    setCompany({
      ...company,
      [prop]: value,
    });
    setLoading(false);
    // toast notification for onramp
    showToast(`Account has been updated`);

    // if prop is asset or wallet, show disclaimer
    if (prop === "assetId" || prop === "walletId") {
      setShowDisclaimer(true);
    }
  };

  useEffect(() => {
    const getBankAccounts = async () => {
      setLoading(true);
      const { data } = await API.get(`business/bankAccounts/${accountId}`);
      setBankAccounts(data);
      setLoading(false);
    };
    getBankAccounts();

    return () => {
      setBankAccounts([]);
    };
  }, [accountId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `business/onboarding/${accountId}`;
        const { data } = await API.get(url);
        setLoading(true);
        setCompany({
          name: data?.name,
          address: data?.address,
          id: data?.id,
          offRampWithdrawalFrequency: data?.offRampWithdrawalFrequency,
          onRampWithdrawalFrequency: data?.onRampWithdrawalFrequency,
          paymentId: data?.paymentId,
          preferredOnrampAssetId: data?.preferredOnrampAssetId,
          preferredOnrampWalletId: data?.preferredOnrampWalletId,
          hasOffRampAccess: data?.hasOffRampAccess,
          hasOnRampAccess: data?.hasOnRampAccess,
        });
        console.log(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        // Handle error here
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [accountId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const url = `business/${accountId}/externalWallets`;
        const { data } = await API.get(url);
        setExternalWallets(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        // Handle error here
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [accountId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const url = `business/assets`;
        const { data } = await API.get(url);
        setOnRampOptions(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        // Handle error here
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {loading && <Loader />}
      <main className="bg-grey p-6">
        <div className="flex flex-row items-center mb-6 justify-between">
          <h4 className="text-xl font-bold dark:text-black">Settings</h4>
        </div>
        <div className="mt-8">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            On Ramp Instructions
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-500">
            When fiat currency is deposited into your account, it will be
            swapped into your desired asset and transferred to your selected
            wallet based on your withdrawal frequency.
          </p>
          {!company?.hasOnRampAccess && (
            <div className="text-start m-10">
              <h3 className="mt-2 text-sm font-semibold text-gray-900">
                Your account is not enabled for On Ramp access.
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Please reach out to support@getbeam.cash to request access.
              </p>
            </div>
          )}
          {company?.hasOnRampAccess && (
            <dl className="mt-6 space-y-2 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
              <div className="pt-4 sm:flex items-center">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                  Asset
                </dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <select
                    id="asset"
                    name="asset"
                    className="mt-2 block rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-lavender-700 sm:text-sm sm:leading-6"
                    defaultValue="Select Asset"
                    onChange={(e) => {
                      updateAccount("preferredOnrampAssetId", e.target.value);
                    }}
                    value={company?.preferredOnrampAssetId}
                  >
                    <option disabled>Select Asset</option>
                    {onRampOptions.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.symbol} on {option.network}
                      </option>
                    ))}
                  </select>
                </dd>
              </div>
              <div className="pt-4 sm:flex items-center">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                  Wallet
                </dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <select
                    id="wallet"
                    name="wallet"
                    className="mt-2 block rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-lavender-700 sm:text-sm sm:leading-6"
                    defaultValue="Select Wallet"
                    onChange={(e) => {
                      updateAccount("preferredOnrampWalletId", e.target.value);
                    }}
                    value={company?.preferredOnrampWalletId}
                  >
                    <option disabled>Select Wallet</option>
                    {externalWallets.map((wallet) => (
                      <option key={wallet.id} value={wallet.id}>
                        {wallet.description} - {wallet.address.slice(0, 6)}
                      </option>
                    ))}
                  </select>
                </dd>
              </div>
              <div className="pt-4 sm:flex items-center">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                  Withdrawal Frequency
                </dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <select
                    id="withdrawalFrequency"
                    name="withdrawalFrequency"
                    className="mt-2 block rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-lavender-700 sm:text-sm sm:leading-6"
                    onChange={(e) => {
                      updateAccount(
                        "onRampWithdrawalFrequency",
                        e.target.value,
                      );
                    }}
                    value={company?.onRampWithdrawalFrequency}
                  >
                    <option value="continuous">continuous</option>
                    <option value="onDemand">on demand</option>
                  </select>
                </dd>
              </div>
            </dl>
          )}
        </div>

        <div className="mt-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Off Ramp Instructions
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-500">
            When crypto or stablecoins are deposited into your account, they
            will be swapped into your desired fiat currency and withdrawn to
            your selected bank account based on your withdrawal frequency.
          </p>
          {!company?.hasOffRampAccess && (
            <div className="text-start m-10">
              <h3 className="mt-2 text-sm font-semibold text-gray-900">
                Your account is not enabled for Off Ramp access.
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Please reach out to support@getbeam.cash to request access.
              </p>
            </div>
          )}
          {company?.hasOffRampAccess && (
            <dl className="mt-6 space-y-2 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
              <div className="pt-4 sm:flex items-center">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                  Fiat
                </dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <select
                    id="fiat"
                    name="fiat"
                    className="mt-2 block rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-lavender-700 sm:text-sm sm:leading-6"
                    defaultValue="USD"
                    value="USD"
                  >
                    <option disabled>Select Fiat</option>
                    <option value="USD">USD</option>
                  </select>
                </dd>
              </div>
              <div className="pt-4 sm:flex items-center">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                  Withdrawal Frequency
                </dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <select
                    id="withdrawalFrequency"
                    name="withdrawalFrequency"
                    className="mt-2 block rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-lavender-700 sm:text-sm sm:leading-6"
                    onChange={(e) => {
                      updateAccount(
                        "offRampWithdrawalFrequency",
                        e.target.value,
                      );
                    }}
                    value={company?.offRampWithdrawalFrequency}
                  >
                    <option value="continuous">continuous</option>
                    <option value="onDemand">on demand</option>
                  </select>
                </dd>
              </div>
              <div className="pt-4 sm:flex items-center">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                  Bank Account
                </dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <select
                    id="withdrawalFrequency"
                    name="withdrawalFrequency"
                    className="mt-2 block rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-lavender-700 sm:text-sm sm:leading-6"
                    onChange={(e) => {
                      updateAccount("paymentId", e.target.value);
                    }}
                    value={company?.paymentId}
                  >
                    {bankAccounts?.map((bank, index) => (
                      <option key={bank.id} value={bank.id}>
                        {bank?.description || bank?.bankName} - {bank.last4}
                      </option>
                    ))}
                  </select>
                </dd>
              </div>
            </dl>
          )}
        </div>
      </main>
    </>
  );
};

export default Settings;
